import React, { FC, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CommonInput } from "@/components/basic/CommonInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import GroupTitle from "@/components/basic/GroupTitle";
import FormGroupTitle from "@/components/basic/FormGroupTitle";
import { CommonSelect } from "@/components/basic/CommonSelect";
import { areaOptions } from "@/components/basic/area";
import { useFormType } from "@/components/share/useFormType";

interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: any;
  isDisable?: boolean;
}
const Form: FC<Props> = ({
  data,
  onError,
  pageButton: PageButton,
  onSubmit,
  isDisable = false,
}) => {
  const schema = yup.object().shape({
    firstName: yup.string().required("姓は入力必須項目です。"),
    lastName: yup.string().required("名は入力必須項目です。"),
    firstPostId: yup
      .string()
      .matches(/^\d{3}$/, {
        message: "有効な郵便番号を入力してください。",
        excludeEmptyString: true,
      })
      .required("有効な郵便番号を入力してください。"),
    lastPostId: yup
      .string()
      .matches(/^\d{4}$/, {
        message: "有効な郵便番号を入力してください。",
        excludeEmptyString: true,
      })
      .required("有効な郵便番号を入力してください。"),
    block: yup.string().required("住所は入力必須項目です。"),
    country: yup.string().required("住所は入力必須項目です。"),
    area: yup.number().required("住所は入力必須項目です。"),
    building: yup.string().nullable(),
    phone: yup
      .string()
      .matches(/^[0-9]{5,15}$/, {
        message: "入力できるのは半角数字のみです。",
        excludeEmptyString: true,
      })
      .required("入力できるのは半角数字のみです。"),
    phoneAgain: yup
      .string()
      .required("入力された携帯電話番号が一致しません。")
      .oneOf([yup.ref("phone"), ""], "入力された携帯電話番号が一致しません。"),
    mail: yup
      .string()
      // .matches(/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/, {
      //   message: "有効なメールアドレスを入力してください。",
      //   excludeEmptyString: true,
      // })
      .email("有効なメールアドレスを入力してください。")
      .required("有効なメールアドレスを入力してください。"),
    mailAgain: yup
      .string()
      .required("入力されたメールアドレスが一致しません。")
      .oneOf([yup.ref("mail"), ""], "入力されたメールアドレスが一致しません。"),
  });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      firstPostId: data?.firstPostId || "",
      lastPostId: data?.lastPostId || "",
      country: data?.country || "会津若松市",
      area: data?.area || 7,
      block: data?.block || "",
      building: data?.building || "",
      phone: data?.phone || "",
      phoneAgain: data?.phoneAgain || "",
      mail: data?.mail || "",
      mailAgain: data?.mailAgain || "",
    },
  });

  const handleClick = (data: any) => {
    handleSubmit(
      value => {
        onSubmit({ ...value, ...data });
      },
      value => {
        onError(value);
      },
    )();
  };

  return (
    <>
      <GroupTitle text={"基本情報"} sx={{ mb: 3, mt: 4 }} />

      <Grid mb={2} container spacing={2}>
        <Grid item xs={12} md={3}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.firstName || ""}
            sx={{ paddingBottom: "8px" }}
            required={true}
            name="firstName"
            placeholder={"例：山田"}
            control={control}
            errors={errors}
            label={"姓"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.lastName || ""}
            sx={{ paddingBottom: "8px" }}
            required={true}
            name="lastName"
            placeholder={"例：太郎"}
            control={control}
            errors={errors}
            label={"名"}
          />
        </Grid>
      </Grid>

      <Grid mb={2} container spacing={1.9}>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <CommonInput
              disabled={isDisable}
              defaultValue={data?.firstPostId || ""}
              maxLength={3}
              sx={{ paddingBottom: "8px" }}
              required={true}
              name="firstPostId"
              placeholder={"000"}
              control={control}
              errors={errors}
              isShowError={false}
              label={"郵便番号"}
            />

            <Box>
              <Box
                sx={{
                  pt: "40px",
                  px: 1,
                }}
              >
                -
              </Box>
            </Box>

            <CommonInput
              disabled={isDisable}
              defaultValue={data?.lastPostId || ""}
              maxLength={4}
              sx={{ paddingBottom: "8px" }}
              name="lastPostId"
              placeholder={"0000"}
              control={control}
              errors={errors}
              isShowError={false}
            />
          </Box>
          {(errors["lastPostId"]?.message ||
            errors["firstPostId"]?.message) && (
            <Typography
              sx={{ fontSize: "0.88rem", fontWeight: 100, color: "#F44336" }}
            >
              有効な郵便番号を入力してください。
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={2.5}>
          <CommonSelect
            disabled={isDisable}
            defaultValue={data?.area || ""}
            required={true}
            control={control}
            errors={errors}
            name={"area"}
            placeholder={""}
            label={"都道府県"}
            options={areaOptions}
          >
            {areaOptions?.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </CommonSelect>
        </Grid>

        <Grid item xs={12} md={3}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.country || ""}
            sx={{ paddingBottom: "8px" }}
            required={true}
            name="country"
            placeholder={"市区町村"}
            control={control}
            errors={errors}
            label={"市区町村"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.block || ""}
            sx={{ paddingBottom: "8px" }}
            required={true}
            name="block"
            placeholder={"例：3-46"}
            control={control}
            errors={errors}
            label={"番地"}
          />
        </Grid>
      </Grid>

      <Grid mb={2} container spacing={2}>
        <Grid item xs={12} md={3}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.building || ""}
            sx={{ paddingBottom: "8px" }}
            name="building"
            placeholder={"例：○○ビル 3F"}
            control={control}
            errors={errors}
            label={"建物名・部屋番号など"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={isDisable ? 2 : 0}>
        <Grid item xs={12} md={4}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.phone || ""}
            subLabel={
              "環境価値はアプリ『会津財布』に還元するため、市民ポータルに登録済または登録予定の携帯電話番号を入力してください。異なる番号を入力した場合、キャッシュバックの受け取りができません。"
            }
            onPaste={e => {
              e.preventDefault();
            }}
            sx={{ paddingBottom: "8px" }}
            name="phone"
            onChangeAction={() => {
              trigger("phoneAgain");
            }}
            placeholder={"例：00012345678"}
            control={control}
            errors={errors}
            label={"携帯番号"}
            required={true}
          />
        </Grid>
      </Grid>
      {!isDisable ? (
        <Grid mb={2} container spacing={2}>
          <Grid item xs={12} md={4}>
            <CommonInput
              disabled={isDisable}
              defaultValue={data?.phoneAgain || ""}
              onPaste={e => {
                e.preventDefault();
              }}
              sx={{ paddingBottom: "8px" }}
              name="phoneAgain"
              placeholder={"確認のため再度ご入力下さい"}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CommonInput
            disabled={isDisable}
            onPaste={e => {
              e.preventDefault();
            }}
            defaultValue={data?.mail || ""}
            sx={{ paddingBottom: "8px" }}
            name="mail"
            onChangeAction={() => {
              trigger("mailAgain");
            }}
            placeholder={"例：xxx@yyy.zz"}
            control={control}
            errors={errors}
            label={"メールアドレス"}
            required={true}
          />
        </Grid>
      </Grid>
      {!isDisable ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CommonInput
              disabled={isDisable}
              onPaste={e => {
                e.preventDefault();
              }}
              defaultValue={data?.mailAgain || ""}
              sx={{ paddingBottom: "8px" }}
              name="mailAgain"
              placeholder={"確認のため再度ご入力下さい"}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      ) : null}

      {PageButton && <PageButton onClickSubmit={handleClick}></PageButton>}
    </>
  );
};

const UserInfoForm: FC<Props> = ({
  data,
  onError,
  pageButton,
  onSubmit,
  isDisable = false,
}) => {
  const type = useFormType();

  useEffect(() => {
    //@ts-ignore
    gtag("event", "page_view", {
      page_title: `Type${type}-STEP1（ユーザー情報）`,
      page_location: `/form/${type?.toLowerCase()}/userinfo`,
    });
  }, []);

  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "#F0F0F0",
        borderRadius: "5px",
      }}
    >
      <FormGroupTitle text={"ユーザー情報"} />
      <Form
        isDisable={isDisable}
        data={data}
        pageButton={pageButton}
        onSubmit={onSubmit}
        onError={onError}
      />
    </Box>
  );
};

export default UserInfoForm;
