import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { CommonInput } from "@/components/basic/CommonInput";
import GroupTitle from "@/components/basic/GroupTitle";
import { FC } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "700px",
    },
  },

  "& .MuiDialog-paperWidthMd": {
    minWidth: "1000px",
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (params: any) => any;
  selectedValue?: any;
}
export const DeviceInfoDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  selectedValue,
}) => {
  const schema = yup.object().shape({
    deviceType: yup.string(),
    maker: yup.string(),
    count: yup
      .string()
      .nullable()
      .matches(/^\d+$/, {
        message: "入力できるのは正の整数のみです。",
        excludeEmptyString: true,
      })

      .transform(value => (value ? value : null)),
    pid: yup.string(),
    power: yup
      .string()
      .nullable()
      .matches(/^\d+(\.\d+)?$/, {
        message: "入力できるのは正数および小数点のみです。",
        excludeEmptyString: true,
      }),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      deviceType: selectedValue.deviceType || "",
      maker: selectedValue.maker || "",
      count: selectedValue.count,
      pid: selectedValue.pid || "",
      power: selectedValue.power || "",
    },
  });

  const handleClick = () => {
    handleSubmit((value: any) => {
      onSubmit({
        deviceType: value.deviceType || selectedValue.deviceType || "",
        maker: value.maker || selectedValue.maker || "",
        count: value.count || selectedValue.count,
        pid: value.pid || selectedValue.pid || "",
        power: value.power || selectedValue.power || "",
        id: value.id || selectedValue.id,
      });
      handleClose();
    })();
  };
  const handleClose = () => {
    reset();
    onClose && onClose();
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <BootstrapDialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent
          sx={{
            background: "white",
            padding: "16px",
          }}
        >
          <GroupTitle text={"【パワーコンディショナーについて】"} />

          <Grid container spacing={2} my={1}>
            <Grid item xs={12} md={4}>
              <CommonInput
                defaultValue={selectedValue.maker || ""}
                sx={{ paddingBottom: "8px" }}
                name="maker"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"メーカー"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CommonInput
                defaultValue={selectedValue.deviceType || ""}
                sx={{ paddingBottom: "8px" }}
                name="deviceType"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"型式"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CommonInput
                defaultValue={selectedValue.count || ""}
                sx={{ paddingBottom: "8px" }}
                name="count"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"台数"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            <Grid item xs={12} md={4}>
              <CommonInput
                defaultValue={selectedValue.pid || ""}
                sx={{ paddingBottom: "8px" }}
                name="pid"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"固有番号（シリアル番号等）"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CommonInput
                defaultValue={selectedValue.power || ""}
                sx={{ paddingBottom: "8px" }}
                name="power"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"夜間待機電力（kWh）"}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{ mr: 3, width: "150px", py: 1.2, mt: 1 }}
              onClick={handleClose}
            >
              キャンセル
            </Button>
            <Button
              sx={{ width: "150px", py: 1.2 }}
              onClick={handleClick}
              variant="contained"
            >
              保存
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
