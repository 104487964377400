import UserInfoForm from "@/components/form/UserInfoForm";
import React, { FC } from "react";
import { Box } from "@mui/material";
import PVForm from "@/components/form/PVForm";
import ContractForm from "@/components/form/ContractForm";
import MoreDeviceTypeCForm from "@/components/form/MoreDeviceTypeCForm";
interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: {
    userForm: any;
    pvForm: any;
    moreDevice: any;
    contractForm: any;
  };
}
const FinalCheckFormC: FC<Props> = ({
  data,
  onError,
  pageButton: PageButton,
  onSubmit,
}) => {
  return (
    <>
      <UserInfoForm
        isDisable={true}
        data={data?.userForm}
        onError={onError}
        onSubmit={() => {}}
      />
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#F0F0F0",
          borderTop: "1px dashed #BBBCBC",
        }}
      />
      <PVForm
        isDisable={true}
        data={data?.pvForm}
        onError={onError}
        onSubmit={() => {}}
      />
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#F0F0F0",
          borderTop: "1px dashed #BBBCBC",
        }}
      />
      <MoreDeviceTypeCForm
        isDisable={true}
        data={data?.moreDevice}
        onError={onError}
        onSubmit={() => {}}
      />
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#F0F0F0",
          borderTop: "1px dashed #BBBCBC",
        }}
      />
      <ContractForm
        isDisable={true}
        data={data?.contractForm}
        onError={onError}
        pageButton={PageButton}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default FinalCheckFormC;
