import { Box, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import React, { FC, useContext, useEffect, useState } from "react";
import { FormProviderContext } from "../context/FormProvider";
import { useLocation } from "react-router-dom";
import { TimeProviderContext } from "@/components/context/TimeProvider";

type PageType = "a" | "b" | "c";
const TotalPage = {
  a: 3,
  b: 5,
  c: 5,
};
export const PageButton: FC<{
  onClickSubmit: (data: any) => void;
  isEnableButton?: boolean;
}> = ({ onClickSubmit, isEnableButton = true }) => {
  const { page, setPage } = useContext(FormProviderContext);
  const [type, setType] = useState<PageType | null>(null);
  const theme = useTheme();
  const top = useMediaQuery(theme.breakpoints.down("md")) ? 0 : 300;

  const { setReset } = useContext(TimeProviderContext);

  const handleClick = () => {
    onClickSubmit && onClickSubmit({ step: "next" });
    setReset && setReset(true);
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleBack = () => {
    onClickSubmit && onClickSubmit({ step: "pre" });
    setReset && setReset(true);
    page && setPage && setPage(page - 1);
  };
  const { pathname } = useLocation();

  const getPageButtonName = () => {
    if (type && page === TotalPage[type]) {
      return "送信する";
    } else if (type && page === TotalPage[type] - 1) {
      return "確認する";
    } else {
      return "次へ >";
    }
  };
  useEffect(() => {
    const targetPathName = pathname.toLowerCase();
    if (targetPathName.includes("a")) {
      setType("a");
    }
    if (targetPathName.includes("b")) {
      setType("b");
    }
    if (targetPathName.includes("c")) {
      setType("c");
    }
  }, []);

  return (
    <Box
      mt={4}
      pt={4}
      pb={1}
      sx={{
        display: "flex",
        justifyContent: "end",
        backgroundColor: "#F0F0F0",
        borderTop: "1px dashed #BBBCBC",
      }}
    >
      {page && page > 1 && (
        <Button
          sx={{ mr: 3, width: "150px", py: 1.2 }}
          onClick={() => handleBack()}
        >
          戻る
        </Button>
      )}
      <Button
        sx={{ width: "150px", py: 1.2 }}
        onClick={handleClick}
        variant="contained"
        disabled={!isEnableButton}
      >
        {getPageButtonName()}
      </Button>
    </Box>
  );
};
