import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CommonInput } from "@/components/basic/CommonInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import GroupTitle from "@/components/basic/GroupTitle";
import FormGroupTitle from "@/components/basic/FormGroupTitle";
import { CommonUpload } from "@/components/basic/CommonUpload";
import { CommonDatePicker } from "@/components/basic/CommonDatePIcker";
import { DeviceTable } from "@/components/form/widget/DeviceTable";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import InfoIcon from "@/components/icons/InfoIcon";
import Button from "@mui/material/Button";
import { useFormType } from "@/components/share/useFormType";

interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: any;
  isDisable?: boolean;
}
const Form: FC<Props> = ({
  data,
  onError,
  pageButton: PageButton,
  onSubmit,
  isDisable = false,
}) => {
  const [pvFile, setPvFile] = useState(data?.pvFile || "");
  const [systemFile, setSystemFile] = useState(data?.systemFile || "");
  const [devices, setDevices] = useState(data?.devices || []);

  const schema = yup.object().shape({
    date: yup.string().nullable(),
    power: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, {
        message: "入力できるのは正数および小数点のみです。",
        excludeEmptyString: true,
      })
      .nullable(),
    pvFile: yup.string().nullable(),
    systemFile: yup.string().nullable(),
    devices: yup.array().nullable(),
  });
  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      date: data?.date || "",
      power: data?.power || "",
      pvFile: data?.pvFile || "",
      systemFile: data?.systemFile || "",
      devices: data?.devices || [],
    },
  });

  const handleClick = (data: any) => {
    handleSubmit(
      value => {
        onSubmit({
          systemFile,
          pvFile,
          date: value.date,
          power: value.power,
          devices,
          ...data,
        });
      },
      value => {
        onError(value);
      },
    )();
  };

  const [openInfo, setOpenInfo] = useState(false);

  return (
    <>
      <GroupTitle text={"【太陽光発電設備について】"} sx={{ mb: 3, mt: 4 }} />
      <Dialog open={openInfo}>
        <DialogContent
          sx={{
            background: "white",
            padding: "16px",
          }}
        >
          <GroupTitle text={"【サンプル】"} sx={{ mb: 3, mt: 4 }} />

          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
            }}
            alt="The house from the offer."
            src="/images/info.png"
          />
          <Button
            sx={{ width: "100%" }}
            onClick={() => setOpenInfo(false)}
            variant="outlined"
          >
            閉じる
          </Button>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3.5}>
          <CommonDatePicker
            disabled={isDisable}
            defaultValue={data?.date ? new Date(data?.date) : null}
            placeholder={""}
            sx={{ paddingBottom: "8px" }}
            name="date"
            control={control}
            errors={errors}
            label={"稼働開始日"}
            info={
              <Box
                component={"span"}
                sx={{ display: "inline-flex", cursor: "pointer" }}
                onClick={() => setOpenInfo(true)}
              >
                <InfoIcon fontSize={"small"} />
                <Typography
                  sx={{
                    fontSize: "0.88rem",
                    fontWeight: 700,
                    textDecoration: "underline",
                    color: "#97A0AF",
                  }}
                >
                  参照
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} md={3.5}>
          <CommonInput
            disabled={isDisable}
            defaultValue={data?.power || ""}
            sx={{ paddingBottom: "8px" }}
            name="power"
            placeholder={"入力する"}
            control={control}
            errors={errors}
            label={"公称最大出力（kW）"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3.5}>
          <Typography
            sx={{
              fontSize: "0.88rem",
              fontWeight: 100,
              color: "#97A0AF",
            }}
          >
            *太陽光発電電⼒受給契約確認書などをご確認ください
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <GroupTitle
            sx={{ mb: 3, mt: 6 }}
            text={"【パワーコンディショナーについて】"}
          />
          <DeviceTable
            isDisable={isDisable}
            onChangeAction={value => setDevices(value)}
            defaultValue={data?.devices || []}
          />
        </Grid>
      </Grid>
      <GroupTitle sx={{ mb: 3, mt: 6 }} text={"【確認書類のアップロード】"} />
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <>
            <CommonUpload
              disabled={isDisable}
              defaultValue={data?.pvFile || ""}
              onChangeAction={value => setPvFile(value)}
              subLabel={"*太陽光発電電⼒受給契約確認書"}
              sx={{ paddingBottom: "8px" }}
              name="pvFile"
              placeholder={""}
              control={control}
              errors={errors}
              label={"稼働開始日の証明書類（PNG、JPG、JPEG、PDF、最大5MB）"}
            />
          </>
        </Grid>
        <Grid item xs={12} md={6}>
          <>
            <CommonUpload
              disabled={isDisable}
              defaultValue={data?.systemFile || ""}
              subLabel={
                "※公称最⼤出⼒、メーカー、型式、台数、製造番号、夜間待機電⼒が確認できる仕様書またはカタログなど"
              }
              onChangeAction={value => setSystemFile(value)}
              sx={{ paddingBottom: "8px" }}
              name="systemFile"
              placeholder={""}
              control={control}
              errors={errors}
              label={"システム関連書類（PNG、JPG、JPEG、PDF、最大5MB）"}
            />
          </>
        </Grid>
      </Grid>
      {PageButton && <PageButton onClickSubmit={handleClick}></PageButton>}
    </>
  );
};
const PVForm: FC<Props> = ({
  data,
  onError,
  pageButton,
  onSubmit,
  isDisable = false,
}) => {
  const type = useFormType();

  useEffect(() => {
    //@ts-ignore
    gtag("event", "page_view", {
      page_title: `Type${type}-STEP2（太陽光発電設備）`,
      page_location: `/form/${type?.toLowerCase()}/photovoltaics`,
    });
  }, []);
  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "#F0F0F0",
        borderRadius: "5px",
      }}
    >
      <FormGroupTitle text={"太陽光発電設備情報"} sx={{ mb: 3 }} />
      <Form
        isDisable={isDisable}
        data={data}
        pageButton={pageButton}
        onSubmit={onSubmit}
        onError={onError}
      />
    </Box>
  );
};

export default PVForm;
