import React, { useContext, useEffect, useState } from "react";
import GroupTitle from "@/components/basic/GroupTitle";
import { Box, Typography } from "@mui/material";
import PVForm from "@/components/form/PVForm";
import Button from "@mui/material/Button";
import ContractForm from "@/components/form/ContractForm";
import UserInfoForm from "@/components/form/UserInfoForm";
import { PageButton } from "@/components/share/PageButton";
import { FormProviderContext } from "@/components/context/FormProvider";
import { FieldErrorDialog } from "@/components/form/FieldErrorDialog";
import MoreDeviceTypeBForm from "@/components/form/MoreDeviceTypeBForm";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { SendDialog } from "@/components/form/SendDialog";
import FinishPage from "@/components/form/FinishPage";
import TimeCount from "@/components/basic/TimeCount";
import FinalCheckFormB from "@/components/form/FinalCheckFormB";

const Info = () => {
  return (
    <Box
      my={2}
      p={2}
      sx={{
        backgroundColor: theme => theme.palette.secondary.main,
        borderRadius: "5px",
      }}
    >
      <GroupTitle
        sx={{ color: "#FFFFFF", textDecoration: "none" }}
        text={
          "申込みにあたり、以下の機器・設備の購入および設置を証明する書類をお手元にご準備ください。"
        }
      />
      <Box my={2}>
        <GroupTitle
          sx={{ color: "#FFFFFF", textDecoration: "none" }}
          text={"【必須】"}
        />
        <Box
          component={"ul"}
          sx={{
            mx: 1,
            my: 0,
            px: 2,
          }}
        >
          <Box
            component={"li"}
            sx={{ color: "#FFFFFF", textDecoration: "none" }}
          >
            <GroupTitle
              sx={{ color: "#FFFFFF", textDecoration: "none" }}
              text={
                "太陽光設備稼働開始日に関する証明書類　例：太陽光発電電力需給契約確認書など"
              }
            />
          </Box>
          <Box
            component={"li"}
            sx={{ color: "#FFFFFF", textDecoration: "none" }}
          >
            <GroupTitle
              sx={{ color: "#FFFFFF", textDecoration: "none" }}
              text={
                "太陽光設備、パワーコンディショナーのメーカー・型式・出力などを確認できる仕様書またはカタログ"
              }
            />
          </Box>
        </Box>
      </Box>

      <Box my={2}>
        <GroupTitle
          sx={{ color: "#FFFFFF", textDecoration: "none" }}
          text={"【追加設備（お持ちの場合）に関する項目】"}
        />
        <Box
          component={"ul"}
          sx={{
            mx: 1,
            my: 0,
            px: 2,
          }}
        >
          <Box
            component={"li"}
            sx={{ color: "#FFFFFF", textDecoration: "none" }}
          >
            <GroupTitle
              sx={{ color: "#FFFFFF", textDecoration: "none" }}
              text={
                "蓄電池/電気自動車/エコキュートの購入・設置を証明できるもの"
              }
            />
          </Box>
          <Box
            component={"li"}
            sx={{ color: "#FFFFFF", textDecoration: "none" }}
          >
            <GroupTitle
              sx={{ color: "#FFFFFF", textDecoration: "none" }}
              text={
                "蓄電池を設置している場合、蓄電池容量・充放電効率が確認できる仕様書又はカタログ"
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const Title = ({ page }: { page: number }) => {
  let pageTitle = "";
  let subTitle = "";

  switch (page) {
    case 1:
      pageTitle =
        "事前準備に関する説明を確認し、プラットフォームご利用予定のお客様情報を入力してください。";
      break;
    case 2:
      pageTitle = "太陽光発電設備に関する情報を入力してください。";
      break;
    case 3:
      pageTitle = "その他追加設備に関する情報を入力してください。";
      break;
    case 4:
      pageTitle = "利用規約に関する内容をご確認ください。";
      break;
    case 5:
      pageTitle = "お申し込みは、まだ完了しておりません。";
      subTitle =
        "以下の入力内容に間違いがないかご確認の上、「送信」をクリックしてください。";
      break;
    default:
  }

  return (
    <>
      <Typography
        id="top"
        variant="h2"
        component="h1"
        my={6}
        sx={{
          fontWeight: 700,
          fontSize: "2.5rem",
          mb: subTitle ? 0 : 4,
        }}
      >
        {pageTitle}
      </Typography>
      {subTitle && (
        <Typography
          id="top"
          variant="h2"
          component="h1"
          my={6}
          sx={{
            fontWeight: 700,
            fontSize: "2.5rem",
            mt: 0,
          }}
        >
          {subTitle}
        </Typography>
      )}
    </>
  );
};

const FormB = () => {
  const [userForm, setUserForm] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [contractForm, setContractForm] = useState("");
  const [pvForm, setPvForm] = useState("");
  const [moreDevice, setMoreDevice] = useState("");
  const [hint, setHint] = useState(true);
  const [sendForm, setSendForm] = useState(false);
  const [sendFormData, setSendFormData] = useState({});

  const { page, setPage } = useContext(FormProviderContext);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const addPage = (step: string) => {
    if (step === "next") {
      setPage && page && setPage(page + 1);
    }
  };
  const handleUserForm = (value: any) => {
    setUserForm(value);
    addPage(value.step);
  };

  const handleFinalCheck = (value: any) => {
    if (value.step === "next") {
      setSendForm(true);
    }
  };
  const handleContractForm = (value: any) => {
    setContractForm(value);
    addPage(value.step);

    if (value.step === "next") {
      const sendData = {
        userForm,
        contractForm: {
          question: value.question,
          checkProtect: value.checkProtect,
          checkPhone: value.checkPhone,
          checkContract: value.checkContract,
        },
        pvForm,
        moreDevice,
      };
      setSendFormData(sendData);
    }
  };
  const handlePvForm = (value: any) => {
    setPvForm(value);
    addPage(value.step);
  };
  const handleMoreDeviceForm = (value: any) => {
    setMoreDevice(value);
    addPage(value.step);
  };

  const handleError = (value: any) => {
    setErrors(value);
    setOpen(true);
  };

  return (
    <>
      <TimeCount />

      {page !== 10 && <Title page={page || 1} />}
      <Box
        sx={{
          background: page === 10 ? "inherit" : "white",
          pb: 3,
          px: 3,
          boxShadow: page === 10 ? "none" : "0px 2px 4px 0px #00000045",
        }}
      >
        {page !== 10 && (
          <>
            <GroupTitle text={"【事前準備說明】"} sx={{ mb: 1, mt: 4 }} />
            <Info />
          </>
        )}
        <Box
          my={page === 10 ? 20 : 0}
          p={2}
          sx={{
            background: page === 10 ? "inherit" : "#F0F0F0",
          }}
        >
          {page === 1 && (
            <UserInfoForm
              data={userForm}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleUserForm}
            />
          )}

          {page === 2 && (
            <PVForm
              data={pvForm}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handlePvForm}
            />
          )}

          {page === 3 && (
            <MoreDeviceTypeBForm
              data={moreDevice}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleMoreDeviceForm}
            />
          )}
          {page === 4 && (
            <ContractForm
              data={contractForm}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleContractForm}
            />
          )}
          {page === 5 && (
            <FinalCheckFormB
              data={sendFormData}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleFinalCheck}
            />
          )}
          {page === 10 && <FinishPage />}

          <Dialog
            disableAutoFocus
            disableRestoreFocus
            open={hint}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px",
                },
              },

              "& .MuiDialog-paperWidthMd": {
                minWidth: "600px",
              },
            }}
          >
            <DialogContent
              sx={{
                background: "white",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: {
                  sm: "100%",
                  md: "600px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
                variant="h4"
              >
                フォームに関する注意事項{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 3,
                  fontSize: "0.8rem",
                  fontWeight: 700,
                }}
              >
                1.
                入力した内容は最後のページにて送信を完了するまで保存されません{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: "0.8rem",
                  fontWeight: 700,
                }}
              >
                2. 120分以上操作がなかった場合、入力した内容は失われます{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontSize: "0.8rem",
                  fontWeight: 700,
                }}
              >
                3.
                任意入力項目が未記入の場合、後ほど弊社担当者よりお伺いする可能性があります{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  mb: 3,
                  fontSize: "0.8rem",
                  fontWeight: 700,
                }}
              >
                4. フォームの記入にかかる時間はおよそ 20 分程度です
              </Typography>
              <Button
                sx={{ width: "30%" }}
                onClick={() => setHint(false)}
                variant="contained"
              >
                OK
              </Button>
            </DialogContent>
          </Dialog>
          <SendDialog
            sendFormData={sendFormData}
            open={sendForm}
            handleClose={() => setSendForm(false)}
          />

          <FieldErrorDialog
            errors={errors}
            open={open}
            handleClose={() => setOpen(false)}
          />
        </Box>
      </Box>
    </>
  );
};

export default FormB;
