import React from "react";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { Control, Controller } from "react-hook-form";
import { Box } from "@mui/material";
import FormLabel from "@/components/basic/FormLabel";

type CommonInputProps = {
  maxLength?: number;
  label?: string;
  control: Control<any, any>;
  error?: boolean;
  errorMessage?: string;
  errors: {
    [x: string]: {
      message?: string;
    };
  };
  name: string;
  onChangeAction?: () => void;
  placeholder: string;
  subLabel?: string;
  isShowError?: boolean;
  endAdornment?: any;
  labelColor?: string;
} & TextFieldProps;

export const CommonInput: React.FunctionComponent<CommonInputProps> = ({
  onChangeAction,
  ...props
}) => {
  const {
    subLabel,
    required,
    maxLength,
    name,
    label,
    placeholder,
    errors,
    type,
    isShowError = true,
    labelColor,
    endAdornment,
    disabled = false,
  } = props;

  const setEmptyValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (
      event.target.value === "" &&
      (props.type === "tel" || props.type === "number")
    ) {
      return { target: { value: undefined } };
    }
    return event;
  };

  const setCurrentValue = (value?: any) => {
    if (
      (props.type === "tel" || props.type === "number") &&
      (value === 0 || value === "0")
    ) {
      return value;
    }
    return value || "";
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange, onBlur } }) => (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FormLabel
            required={required}
            name={name}
            label={label}
            subLabel={subLabel}
            labelColor={labelColor}
          />

          <TextField
            className="common"
            onBlur={onBlur}
            type={type}
            variant="outlined"
            color="primary"
            id={name}
            hiddenLabel
            size="small"
            InputLabelProps={{
              style: { backgroundColor: "#FFFFFF" },
            }}
            inputProps={{
              endAdornment,
              maxLength: maxLength,
              sx: {
                backgroundColor: disabled ? "none" : "#FFFFFF",
                WebkitTextFillColor:
                  disabled && !value ? "" : "#1B2433 !important",
              },
            }}
            InputProps={{
              endAdornment,
            }}
            value={disabled && !value ? "--" : setCurrentValue(value)}
            onChange={event => {
              onChange(setEmptyValue(event));
              onChangeAction && onChangeAction();
            }}
            error={!!errors[name]}
            helperText={isShowError && errors[name]?.message}
            sx={{
              height: "40px",
            }}
            {...props}
            label={""}
            placeholder={placeholder}
          />
        </Box>
      )}
    />
  );
};
