import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { FC, useContext, useState } from "react";
import { FormProviderContext } from "@/components/context/FormProvider";
import { areaOptions } from "@/components/basic/area";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { TimeProviderContext } from "@/components/context/TimeProvider";
interface Props {
  open: boolean;
  handleClose: () => void;
  sendFormData: any;
}

export const SendDialog: FC<Props> = ({ open, handleClose, sendFormData }) => {
  const { setPage } = useContext(FormProviderContext);
  const { stopTimeout } = useContext(TimeProviderContext);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isError, setIsError] = useState(false);

  const { pathname } = useLocation();

  const handleClick = () => {
    fetchFormApi();
    stopTimeout && stopTimeout();
    handleClose();
  };

  const fetchFormApi = async () => {
    try {
      const formdata = new FormData();

      const { userForm, contractForm, pvForm, moreDevice } = sendFormData;

      const area = areaOptions.find(item => item.value === userForm.area)
        ?.label;

      const address =
        area + userForm?.country + userForm?.block + userForm?.building;
      let type = "";
      if (pathname.includes("a")) {
        type = "a";
      }
      if (pathname.includes("b")) {
        type = "b";
      }
      if (pathname.includes("c")) {
        type = "c";
      }

      const pv =
        pvForm?.devices?.map((item: any) => {
          return {
            amount: item.count,
            model: item.deviceType,
            manufacturer: item.maker,
            serial_no: item.pid,
            pv_nighttime_standby_power: item.power,
          };
        }) || [];

      const date = pvForm?.date && format(new Date(pvForm?.date), "yyyy-MM-dd");
      formdata.append("cate", type || "");
      formdata.append("user_first_name", userForm.firstName);
      formdata.append("user_last_name", userForm.lastName);
      formdata.append("postcode", userForm.firstPostId + userForm.lastPostId);
      formdata.append("phone_no", userForm.phone);
      formdata.append("full_address", address);
      formdata.append("email", userForm.mail);
      formdata.append("max_output_power", pvForm?.power || "");
      formdata.append("start_date", date || "");
      formdata.append("pv_devices", JSON.stringify(pv));
      formdata.append("start_date_cert_file", pvForm?.pvFile || "");
      formdata.append("extra_system_info_file", pvForm?.systemFile || "");
      formdata.append("storage_capacity", moreDevice?.battlePower || "");
      formdata.append(
        "storage_charging_efficiency",
        moreDevice?.battleChargePercent || "",
      );
      formdata.append(
        "storage_discharge_efficiency",
        moreDevice?.battleDisChargePercent || "",
      );
      formdata.append(
        "storage_start_date_cert_file",
        moreDevice?.proveFile || "",
      );
      formdata.append(
        "storage_other_system_info_file",
        moreDevice?.systemFile || "",
      );
      formdata.append("ev_cert_file", moreDevice?.carProveFile || "");
      formdata.append("ev_subsidy_check", moreDevice?.price || "");
      formdata.append("ecocute_cert_file", moreDevice?.buyProveFile || "");
      formdata.append("inquiry", contractForm?.question || "");
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      await fetch(
        `${process.env.VITE_API_URL}/application_form/send`,
        // @ts-ignore
        requestOptions,
      );
      setPage && setPage(10);
    } catch (e) {
      //@ts-ignore
      const errorMsg = e?.response?.status;
      setIsError(true);
      setTitle("問題が発生しました");
      setContent(
        `問題が発生しました。しばらくしてから再度お試しいただくか、サービスプロバイダまでお問い合わせください。${
          errorMsg ? "(" + errorMsg + ")" : ""
        }`,
      );
    }
  };
  return (
    <>
      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={isError}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 5,
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            {content}
          </Typography>

          <Button
            sx={{ width: "30%", mt: 3 }}
            onClick={() => {
              setIsError(false);
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: {
              sm: "100%",
              md: "600px",
            },
            overflowX: "hidden",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            フォームを送信しますか？
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            記入した全ての情報が正しいことを確認してから、送信してください。{" "}
          </Typography>

          <Box
            mt={4}
            pb={1}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ mr: 1, width: "140px", py: 1.2 }}
              onClick={() => handleClose()}
            >
              戻る
            </Button>

            <Button
              sx={{ width: "140px", py: 1.2 }}
              onClick={handleClick}
              variant="contained"
            >
              送信
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
