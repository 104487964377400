import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";
import GroupTitle from "@/components/basic/GroupTitle";
import FormGroupTitle from "@/components/basic/FormGroupTitle";
import { useFormType } from "@/components/share/useFormType";

const StyledTextarea = styled(TextareaAutosize)(
  () => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #BBBCBC;
    textarea {
      resize: both;
      width: 100%;
    }
  `,
);

interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: any;
  isDisable?: boolean;
}
const Form: FC<Props> = ({
  data,
  pageButton: PageButton,
  onSubmit,
  isDisable = false,
}) => {
  const [contractStatus, setContractStatus] = useState(data?.checkContract);
  const [checkProtect, setCheckProtect] = useState(data?.checkProtect);
  const [checkPhone, setCheckPhone] = useState(data?.checkPhone);
  const [question, setQuestion] = useState(data?.question);
  const [checkContract, setCheckContract] = useState(data?.checkContract);
  const options = {
    root: document.querySelector("#scrollArea"),
    rootMargin: "0px",
    threshold: 1.0,
  };

  const handleClick = (data: any) => {
    onSubmit({
      ...data,
      checkProtect,
      checkPhone,
      checkContract,
      question,
    });
  };
  const callback = (entries: any[]) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.6) {
        setContractStatus(true);
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  useEffect(() => {
    const target = document.querySelector("#target");
    if (target) {
      observer.observe(target);
    }
  }, []);

  return (
    <>
      <GroupTitle
        text={"【環境価値の地域循環サービスプラットフォーム会員規約】"}
        sx={{ mb: 2, mt: 6 }}
      />

      <Grid mb={2} container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              border: "1px solid #BBBCBC",
              background: "white",
              borderRadius: "4px",
              minHeight: "40px",
              p: 2,
              height: "50vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Typography textAlign={"center"}>会員規約</Typography>

            <Typography>（目的）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第1条
            </Typography>
            <Typography>
              環境価値の地域循環サービスプラットフォーム（以下「本会」という。）は、コスモ石油マーケティング株式会社が実施する「環境価値の地域循環サービス」の一環として、本会会員が太陽光発電設備を使用することで削減された温室効果ガス排出量を、国内における地球温暖化対策のための排出削減・吸収量認証制度
              (以下「Ｊ－クレジット制度」という。) 実施要綱
              (平成25年4月17日経済産業省、環境省、農林水産省策定)
              に基づき、Ｊ－クレジットとして認証を受けることで、環境価値の「見える化」を図るとともに、創出されたＪ－クレジットをコスモ石油マーケティング株式会社が環境先進企業などへ分配もしくは販売を行うことを目的とする。販売した場合、その収益は本会の運営費及び別に定める方法での会員への還元に充てるものとする。
            </Typography>
            <Typography>（運営及び管理）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第2条
            </Typography>
            <Typography>
              本会の運営及び管理は、コスモ石油マーケティング株式会社
              (以下「運営・管理者」という。)が行う。
            </Typography>
            <Typography>2 運営・管理者は、次に掲げる業務を行う。</Typography>
            <Typography>
              (1)
              本会入会届の受理、退会手続、参加要件の確認、会員情報の管理・記録に係る業務
            </Typography>
            <Typography>
              (2) Ｊ－クレジット認証委員会へのプロジェクト登録申請に係る業務
            </Typography>
            <Typography>
              (3)
              本会会員の保有する太陽光発電設備および蓄電池のモニタリングデータ収集および保管等の業務
            </Typography>
            <Typography>
              (4)
              Ｊ－クレジット認証委員会への実績報告及びＪ－クレジットの認証申請その他Ｊ－クレジット制度に係る各種申請・報告に係る業務
            </Typography>
            <Typography>
              (5) 認証されたＪ－クレジットの売却に関する業務
            </Typography>
            <Typography>(6) クレジット収益の活用・還元に関する業務</Typography>
            <Typography>(7) 前各号に付随・関連する一切の業務</Typography>
            <Typography>
              3
              本会の運営及び管理に係わる業務の一部または全部を当社の指定業者に委託することがある。
            </Typography>

            <Typography>（入会申込）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第3条
            </Typography>

            <Typography>
              本会に入会しようとする者(以下、「入会希望者」という。)は、運営・管理者が指定するWEBサイトまたは書面等にて「環境価値の地域循環サービスプラットフォーム会員規約」(以下、「本規約」という。)に同意したうえで、必要事項を記入し提出する事で入会申込を行うものとする。
            </Typography>
            <Typography>（入会資格）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第4条
            </Typography>
            <Typography>
              入会希望者は、次に掲げる要件を全て満たさなければならない。
            </Typography>
            <Typography>
              (1)
              入会届を提出した日の２年前の日以降に、太陽光発電設備を住宅に設置し、発電された電力の全部
              又は一部を自家消費している又は入会申込の年度内に太陽光発電設備を住宅に設置する予定である
              こと。但し、太陽光発電設備の設置が入会届を提出した日の２年前の日以前であっても、自家消費
              の拡大や電力系統への負荷の軽減、中長期的な発電の継続に資すると運営・管理者が認める追加的設
              備の設置が２年前の日以降であれば対象の太陽光発電設備とみなす。
            </Typography>
            <Typography>
              (2)保有する太陽光発電設備の設置について、建築基準法上の違反が無いこと。
            </Typography>
            <Typography>
              (3)Ｊ－クレジット制度における各種申請に際し、当該入会届に記載された情報を、運営・管理者が使
              用することに同意すること。
            </Typography>
            <Typography>
              (4)保有する太陽光発電設備が運営・管理者が指定する通信機器と接続可能な機能を有していること。
            </Typography>
            <Typography>
              (5)保有する太陽光発電設備と併設して、自家発電装置を設置していないこと。
            </Typography>
            <Typography>
              (6)太陽光発電設備、及び指定製品の保有名義が個人名であること。
            </Typography>
            <Typography>
              (7)Ｊ－クレジット制度における各種申請に際し、当該入会届に記載された以外の情報について、運営・
              管理者が必要とする場合は提供することに同意すること。
            </Typography>
            <Typography>
              (8)太陽光発電設備を使用することによる自家消費分についての環境価値(温室効果ガス排出量の削減
              効果=Ｊ－クレジット)
              を運営・管理者へ無償譲渡すること。また、その結果として「太陽光発電により温室効果ガス排出量を削減した」ことを、会員が主張できなくなること。
            </Typography>
            <Typography>
              (9)本会に登録する太陽光発電設備及び追加設備が、他の類似制度及びＪ－クレジット制度における他のプロジェクトのいずれにおいても登録されていないこと。
            </Typography>
            <Typography>（測定情報）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第5条
            </Typography>
            <Typography>
              運営・管理者は指定の通信機器を通じて、本会会員の温室効果ガスの排出削減効果量の測定にかかる情報（以下、「測定情報」という。）を収集、管理する。
            </Typography>
            <Typography>
              2
              運営・管理者は測定情報を基にJクレジット制度による温室効果ガスの排出削減効果量の認証を行い、認証された温室効果ガスの排出削減効果量をJ－クレジット化する。
            </Typography>
            <Typography>（運営・管理者への協力）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第6条
            </Typography>
            <Typography>
              会員は、運営・管理者が求めるときは、次に掲げる事項について同意し、協力しなければならない。
            </Typography>
            <Typography>
              (1)
              Ｊ－クレジット制度における各種申請に際し、運営・管理者が必要とする情報を提供すること。
            </Typography>
            <Typography>
              (2)
              Ｊ－クレジットの認証に際し、審査機関等が必要に応じて実施する現地調査
              (太陽光発電設備に関する現地確認等をいう) を受けること。
            </Typography>
            <Typography>
              (3) その他、本会の運営及び管理に関して必要なこと。
            </Typography>
            <Typography>（業務の報告）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第7条
            </Typography>
            <Typography>
              運営・管理者は、会員に対して、次に掲げる事項について、年に1回、運営・管理者が別で提供するWEBサイト等で報告を行う。
            </Typography>
            <Typography>
              (1)
              Ｊ－クレジット認証委員会への実績報告及びＪ－クレジットの認証申請
            </Typography>
            <Typography>(2) Ｊ－クレジットの活用状況について</Typography>
            <Typography>（設備の処分等）</Typography>

            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第8条
            </Typography>
            <Typography>
              会員は、第11条に規定する会員資格の有効期間内において、次の各号の一に該当するときは、その旨を運営・管理者に届け出なければならない。
            </Typography>
            <Typography>(1) 太陽光発電設備が毀損又は滅失したとき。</Typography>
            <Typography>
              (2)
              太陽光発電設備を処分(売却、譲渡、交換、貸付又は担保に供すること)しようとするとき。
            </Typography>
            <Typography>
              (3)
              第４条第１項第１号但書の追加的設備の設置により入会資格を満たしたものについて、当該追加的設備が毀損又は滅失したとき。
            </Typography>
            <Typography>
              (4)
              第４条第１項第１号但書の追加的設備の設置により入会資格を満たしたものについて、当該追加的設備を処分(売却、譲渡、交換、貸付又は担保に供すること)しようとするとき。
            </Typography>
            <Typography>（退会）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第9条
            </Typography>
            <Typography>
              会員は、本会を退会しようとするときは、運営・管理者所定の方法により退会手続きをとるものとし、退会手続き完了をもって本会への参加を終了とする。
            </Typography>
            <Typography>
              2
              運営・管理者は、会員が次の各号のいずれかに該当するときは、当該会員の退会措置をとることができる。
            </Typography>
            <Typography>(1) 第4条に定める入会資格を喪失した場合</Typography>
            <Typography>
              (2) 会員の行為が本会の目的に著しく相応しくないと認める場合
            </Typography>
            <Typography>(3) 前項の届出があった場合</Typography>
            <Typography>(4) 第 11条に定める期間を経過した場合</Typography>
            <Typography>(5) 本規約に違反したとき</Typography>
            <Typography>
              (6)
              入会申込の内容その他の運営・管理者への申請・届出等の内容に虚偽があったとき
            </Typography>
            <Typography>
              (7)
              暴力団、暴力団員、暴力団関係団体もしくは関係者、またはその他の反社会的組織に属している者（以下「暴力団員等」といいます）であると認められたとき、または暴力団、暴力団関係団体等の維持、運営に協力もしくは関与し、または暴力団員等と交流している事実が判明したとき。
            </Typography>
            <Typography>
              (8)
              その他、会員として不適当であると運営・管理者が合理的に判断したとき
            </Typography>
            <Typography>（会費）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第10条
            </Typography>
            <Typography>本会の会費は無料とする。</Typography>
            <Typography>（会員資格の有効期間）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第11条
            </Typography>
            <Typography>会員資格の有効期間は、入会後8年間とする。</Typography>
            <Typography>（個人情報・重要情報等の取扱い）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第12条
            </Typography>
            <Typography>
              会員から得られた情報（個人情報、保有設備情報、電力量データ等）は、以下の目的に利用します
            </Typography>
            <Typography>
              (1)Ｊ－クレジット制度に基づく本会の業務遂行のため
            </Typography>
            <Typography>
              (2)電力量データ測定に係る通信機器の設置・設定のサポートのため
            </Typography>
            <Typography>
              (3)アンケート及び省エネルギーに関するサービス・商品の紹介など、電話・電子メール・ダイレクトメール等での情報発信のため
            </Typography>
            <Typography>
              2
              会員は、運営・管理者が個人情報等の一部を以下に定めるところにより共同利用することに同意するものとする。
            </Typography>
            <Typography>
              共同して利用される個人情報等の項目：氏名、住所、電話番号、太陽光設備設置住所、発電量、売電量
              共同して利用する者の範囲：コスモエネルギーホールディングス株式会社及びその子会社、関連会社（
              <Typography
                component={"a"}
                target="_blank"
                href={
                  "https://www.cosmo-energy.co.jp/ja/about/company/group.html"
                }
              >
                https://www.cosmo-energy.co.jp/ja/about/company/group.html{" "}
              </Typography>
              ）
            </Typography>
            <Typography>
              利用する者の利用目的：１項１ないし３号に同じ
              個人情報等の管理について責任を有する者：コスモ石油マーケティング株式会社
              福島県会津若松市東栄町1-77 スマートシティAiCT 桒原龍夫
            </Typography>
            <Typography>
              3
              また、運営・管理者は会員個人を特定できない形態において、電力情報等の個人情報を運営・管理者の裁量で自由に利用又は第三者に開示することができるものとする。
            </Typography>
            <Typography>
              4
              会員は、運営・管理者から、通信機器の設置・設定のサポート等の本会の事務の委託業者に対して、委託業者が運営・管理者からの委託事務の遂行に加えて以下の目的に供するために、個人情報を提供することに同意する。
            </Typography>
            <Typography>
              (1)アンケート及び省エネルギーに関するサービス・商品の紹介を実施するなど、電話・電子メール・ダイレクトメール等での情報発信のため
            </Typography>
            <Typography>
              (2)温室効果ガス排出量削減や省エネルギーに資する設備の斡旋又は販売をすることに個人情報を利用することについて、会員の同意の可否を確認するため
            </Typography>
            <Typography>
              5
              会員は、運営・管理者から以下の者に対して、以下の目的で、個人情報を提供することに同意する。
            </Typography>
            <Typography> (1) Ｊ-クレジット事務局等</Typography>

            <Typography>
              提供を受けるもの：Ｊ－クレジット制度事務局（みずほリサーチ&テクノロジーズ株式会社）及び
              Ｊ－クレジット制度における審査機関（
              <Typography
                component={"a"}
                target="_blank"
                href={"https://japancredit.go.jp/about/vvb/"}
              >
                https://japancredit.go.jp/about/vvb/
              </Typography>
              ）
            </Typography>
            <Typography>
              提供の目的：J －クレジットの申請及び審査のため
            </Typography>
            <Typography>(2)還元について</Typography>
            <Typography>
              提供を受けるもの：Ｊ-クレジットの収益還元にあたる事業者
            </Typography>
            <Typography>
              提供の目的：Ｊ－クレジットの収益の会員への還元実施のため
            </Typography>
            <Typography>
              （6
              運営・管理者は指定する委託業者（電気・通信・住宅設備等事業者等）に対し、個人情報保護法に基づき適切な安全管理措置をとるものとする。
            </Typography>
            <Typography>（本会の一時的な中断、変更、廃止、譲渡）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第13条
            </Typography>
            <Typography>
              運営・管理者は以下の事由により、一時的に本会サービスを中断することがある。本会サービスの中断による損害について、運営・管理者は会員に対し、一切責任を負わないものとする。
            </Typography>
            <Typography>
              （1）サービスを運営するためのシステムの保守、点検、修理などを行う場合。
            </Typography>
            <Typography>
              （2）火災・停電等の非常事態、天変地異、その他不可抗力等によりサービスが実施できなくなった場合。
            </Typography>
            <Typography>
              （3）その他、運用上または技術上、本プロジェクトの一時的な中断を必要とした場合。
            </Typography>
            <Typography>
              2
              運営・管理者は、本会サービスの内容及びサービスを実施する上で必要不可欠な機器やシステム、それらのソフトウェアおよび更新プログラム等を、会員への事前の通知なくして変更することがあるが、会員はそれに同意するものとし、運営・管理者は、会員に対し、一切責任を負わないものとする。
            </Typography>
            <Typography>
              3
              運営・管理者は、本会サービスを、本条記載の場合以外においても最低10日間の予告期間をおいて一時的な中断、変更または廃止することができる。本会サービスの一時的な中断、変更または廃止は、運営・管理者が別で提供するWEBサイト上または別に定める方法で告知するものとし、この手続を行った後に本会サービスの一時的な中断、変更または廃止をした場合には、運営・管理者は、一切の損害賠償等の責を負わないものとする。
            </Typography>
            <Typography>
              4
              運営・管理者は、本会サービスを、最低10日間の予告期間をおいて本規約に基づくすべての権利及び義務を承継、売却、合併、その他の方法で、譲渡することができる。本会サービスの譲渡は、運営・管理者が別で提供するWEBサイト上または別に定める方法で告知するものとし、この手続を行った後に本会サービスの譲渡をした場合には、運営・管理者は、一切の損害賠償等の責を負わないものとする。また、この場合において、会員は運営・管理者がかかる権利及び義務を譲り受ける者に利用者情報、追加情報の開示をすることを了承するものとする。
            </Typography>
            <Typography>（規約の改定）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第14条
            </Typography>
            <Typography>
              本規約は、会員の事前承諾を得ることなく必要に応じて改定できるものとする。なお、変更後の規約については、適宜会員に報告するものとする。
            </Typography>
            <Typography>（反社会的勢力の排除）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第15条
            </Typography>
            <Typography>
              会員は、次の各号のいずれか一にも該当しないことを表明し、かつ将来にわたっても該当しないことを表明し、保証する。
            </Typography>
            <Typography>
              (1)自らが、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロまたは特殊知能暴力集団等その他これらに準じる者（以下総称して「暴力団員等」といいます）であること
            </Typography>
            <Typography>
              (2)自らもしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもって取引を行う等、暴力団員等を利用していると認められる関係を有すること
            </Typography>
            <Typography>
              (3)暴力団員等に対して資金等を提供し、または便宜を供与する等の関与をしていると認められる関係を有すること
            </Typography>
            <Typography>
              2
              会員は、自らまたは第三者を利用して次の各号のいずれか一にでも該当する行為を行わないことを保証する。
            </Typography>
            <Typography>(1)暴力的な要求行為</Typography>
            <Typography>(2)法的な責任を超えた不当な要求行為</Typography>
            <Typography>
              (3)取引に関して、脅迫的な言動をし、または暴力を用いる行為
            </Typography>
            <Typography>
              (4)風説を流布し、偽計を用い、または威力を用いて、運営・管理者の信用を毀損し、または運営・管理者の業務を妨害する行為
            </Typography>
            <Typography>(5)その他前各号に準ずる行為</Typography>
            <Typography>（管轄裁判所）</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第16条
            </Typography>
            <Typography>
              本規約に基づく権利および義務について紛争が生じたときは、東京地方裁判所を第1審の専属的合意管轄裁判所とする。
            </Typography>
            <Typography>(細則)</Typography>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              第17条
            </Typography>
            <Typography>
              本規約に定めるもののほか、本会に関し必要な事項は運営・管理者が定める。
            </Typography>
            <Typography>本規約は、令和 5 年 9 月 1 日から施行する。</Typography>

            <Box id="target" sx={{ height: "10px" }}></Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <>
            <Checkbox
              defaultChecked={data?.checkContract}
              onChange={value => setCheckContract(value.target.checked)}
              id={"checkContract"}
              disabled={!contractStatus || isDisable}
            />
            <Box
              component={"label"}
              sx={{
                fontSize: "0.88rem",
                color: contractStatus ? "#202C40" : "#c0c0c0",
                mb: 1,
              }}
              htmlFor={"checkContract"}
            >
              <Typography
                component={"span"}
                sx={{
                  fontSize: "0.88rem",
                  fontWeight: 700,
                }}
              >
                会員規約
              </Typography>
              を確認し、同意します。
              <Typography component={"span"} color={"red"}>
                *
              </Typography>
            </Box>
          </>
        </Grid>
      </Grid>
      <GroupTitle
        text={"【個人情報の取り扱いについて】"}
        sx={{ mb: 2, mt: 6 }}
      />
      <Grid mb={2} container spacing={1}>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              disabled={isDisable}
              defaultChecked={data?.checkProtect}
              id={"checkProtect"}
              onChange={value => setCheckProtect(value.target.checked)}
            />
            <Box
              component={"label"}
              sx={{
                fontSize: "0.88rem",
                color: "#202C40",
              }}
              htmlFor={"checkProtect"}
            >
              <Typography
                component={"a"}
                target="_blank"
                href={
                  "https://www.cosmo-energy.co.jp/ja/privacy/cosmo-com/policy.html"
                }
                sx={{
                  fontSize: "0.88rem",
                  fontWeight: 700,
                  color: "#2C31A6",
                }}
              >
                プライバシーポリシー
              </Typography>
              を確認し、同意します。
              <Typography component={"span"} color={"red"}>
                *
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              disabled={isDisable}
              defaultChecked={data?.checkPhone}
              id={"checkPhone"}
              onChange={value => setCheckPhone(value.target.checked)}
            />
            <Box
              component={"label"}
              sx={{
                fontSize: "0.88rem",
                color: "#202C40",
                mb: 1,
              }}
              htmlFor={"checkPhone"}
            >
              弊社からの携帯電話またはメールによる連絡に同意します。
              <Typography component={"span"} color={"red"}>
                *
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <GroupTitle text={"【ご質問など】"} sx={{ mb: 2, mt: 6 }} />
      <Grid mb={2} container spacing={2}>
        <Grid item xs={12} sm={12}>
          <StyledTextarea
            disabled={isDisable}
            value={data?.question}
            maxLength={500}
            minRows={10}
            onChange={value => setQuestion(value.target.value)}
          />
        </Grid>
      </Grid>
      {PageButton && (
        <PageButton
          isEnableButton={!!checkProtect && !!checkPhone && !!checkContract}
          onClickSubmit={handleClick}
        ></PageButton>
      )}
    </>
  );
};
const ContractForm: FC<Props> = ({
  data,
  onError,
  pageButton,
  onSubmit,
  isDisable = false,
}) => {
  const type = useFormType();

  useEffect(() => {
    //@ts-ignore
    gtag("event", "page_view", {
      page_title: `Type${type}-STEP4（利用規約）`,
      page_location: `/form/${type?.toLowerCase()}/tos`,
    });
  }, []);
  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "#F0F0F0",
        borderRadius: "5px",
      }}
    >
      <FormGroupTitle text={"利用規約"} />
      <Form
        isDisable={isDisable}
        pageButton={pageButton}
        onSubmit={onSubmit}
        onError={onError}
        data={data}
      />
    </Box>
  );
};

export default ContractForm;
