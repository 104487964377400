import React, { FC, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { Control, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormLabel from "@/components/basic/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

type CommonInputProps = {
  maxLength?: number;
  label?: string;
  subLabel?: string;
  control: Control<any, any>;
  error?: boolean;
  errorMessage?: string;
  errors: {
    [x: string]: {
      message?: string;
    };
  };
  name: string;
  onChangeAction?: (value?: any) => void;
  placeholder: string;
  info?: any;
  disabled?: boolean;
  defaultValue?: { name?: string };
} & TextFieldProps;

const ErrorDialog: FC<{
  errorMsg: string;
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose, errorMsg }) => {
  return (
    <Dialog
      disableAutoFocus
      disableRestoreFocus
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },

        "& .MuiDialog-paperWidthMd": {
          minWidth: "600px",
        },
      }}
    >
      <DialogContent
        sx={{
          background: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: {
            sm: "100%",
            md: "600px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: 700,
          }}
          variant="h4"
        >
          アップロードできませんでした
        </Typography>
        <Typography
          sx={{
            mt: 3,
            fontSize: "0.8rem",
            fontWeight: 700,
          }}
        >
          {errorMsg} のためアップロードに失敗しました。
        </Typography>
        <Typography
          sx={{
            mb: 3,
            fontSize: "0.8rem",
            fontWeight: 700,
          }}
        >
          データを調整してから再度お試しください。
        </Typography>

        <Button
          sx={{ width: "50%", mt: 2 }}
          onClick={() => handleClose()}
          variant="contained"
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export const CommonUpload: React.FunctionComponent<CommonInputProps> = ({
  defaultValue,
  ...props
}) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { onChangeAction, info, subLabel, required, name, label, disabled } =
    props;
  const [downloadLink, setDownloadLink] = useState(() => {
    if (defaultValue) {
      // @ts-ignore
      return window.URL.createObjectURL(defaultValue);
    }
    return "";
  });
  const [fileName, setFileName] = useState(defaultValue?.name || "");
  const [errorMsg, setErrorMsg] = useState("");

  const [open, setOpen] = useState(false);
  const handleFileUpdate = (e: any, onChange: (file: any) => void) => {
    const file = e.target.files[0];

    const allowType = ["image/png", "application/pdf", "image/jpeg"];
    onChange && onChange(file);
    const maxAllowedSize = 5 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      setOpen(true);
      setErrorMsg("ファイルサイズが 5MB を超過している");
    } else if (!allowType.includes(file.type)) {
      setOpen(true);
      setErrorMsg("ファイルタイプが JPG / JPEG / PNG / PDF ではない");
    } else {
      const url = window.URL.createObjectURL(file);
      setDownloadLink(url);
      setFileName(file.name);
      onChangeAction && onChangeAction(file);
    }
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange } }) => (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorDialog
            errorMsg={errorMsg}
            open={open}
            handleClose={() => setOpen(false)}
          />
          <FormLabel
            required={required}
            label={label}
            subLabel={subLabel}
            info={info}
          />

          <Box
            sx={{
              maxWidth: "400px",
              border: "1px solid #BBBCBC",
              background: disabled ? "" : "white",
              borderRadius: "4px",
              minHeight: "40px",
            }}
            py={1}
            px={2}
            mb={1}
          >
            {fileName ? (
              <Box
                component={"a"}
                sx={{
                  color: "primary.main",
                }}
                download={fileName}
                href={downloadLink}
              >
                {fileName}
              </Box>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.88rem",
                  fontWeight: 100,
                  color: "#97A0AF",
                }}
              >
                {disabled && !fileName
                  ? "--"
                  : "「ファイルを選択」をクリックしてアップロードする"}
              </Typography>
            )}
          </Box>

          <input
            accept="image/png,image/jpg,image/jpeg,.pdf"
            style={{ display: "none" }}
            id={name}
            multiple
            type="file"
            onChange={value => handleFileUpdate(value, onChange)}
          />
          {disabled ? null : (
            <Box
              component={"label"}
              sx={{
                width: `${fullScreen ? "100%" : "160px"}`,
              }}
              htmlFor={name}
            >
              <Button
                sx={{
                  width: `${fullScreen ? "100%" : "160px"}`,
                }}
                variant="contained"
                component="span"
              >
                ファイルを選択
              </Button>
            </Box>
          )}
        </Box>
      )}
    />
  );
};
