import { Box } from "@mui/material";
import Select, { SelectProps } from "@mui/material/Select/Select";
import React, { ReactNode, useCallback } from "react";

import { Control, Controller } from "react-hook-form";
import FormLabel from "@/components/basic/FormLabel";
import TextField from "@mui/material/TextField";

type CommonSelectProps = {
  placeholder: string;
  label?: string;
  control: Control<any, any>;
  errors: {
    [x: string]: {
      message?: string;
    };
  };
  error?: boolean;
  errorMessage?: string;
  name: string;
  children: ReactNode;
  onChangeAction?: () => void;
  renderValue?: any;
  options: any;
} & SelectProps;

export const CommonSelect: React.FunctionComponent<CommonSelectProps> = ({
  onChangeAction,
  ...props
}) => {
  const {
    renderValue,
    control,
    required,
    name,
    label,
    errors,
    disabled = false,
    options,
  } = props;

  const displayValue = useCallback((value: string) => {
    if (value) {
      return options?.find((option: any) => {
        if (option.value === value) {
          return option.label;
        }
      })?.label;
    }
    return "--";
  }, []);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Box>
          <FormLabel required={required} name={name} label={label} />
          {disabled ? (
            <TextField
              disabled={disabled}
              className="common"
              variant="outlined"
              color="primary"
              hiddenLabel
              size="small"
              InputLabelProps={{
                style: { backgroundColor: "#FFFFFF" },
              }}
              inputProps={{
                sx: {
                  backgroundColor: disabled ? "none" : "#FFFFFF",
                  WebkitTextFillColor:
                    disabled && !value ? "" : "#1B2433 !important",
                },
              }}
              value={displayValue(value)}
              sx={{
                height: "40px",
              }}
              label={""}
            />
          ) : (
            <Select
              disabled={disabled}
              variant="outlined"
              color="primary"
              id={name}
              size="small"
              inputProps={{
                sx: {
                  backgroundColor: disabled ? "none" : "#FFFFFF",
                  WebkitTextFillColor: "#1B2433 !important",
                },
                placeholder: "選択する",
              }}
              displayEmpty={true}
              placeholder={"選択する"}
              value={value}
              onChange={event => {
                onChange(event);
                onChangeAction && onChangeAction();
              }}
              error={!!errors[name]}
              sx={{
                height: "40px",
                width: "100%",
              }}
              renderValue={renderValue}
            >
              {props.children}
            </Select>
          )}
        </Box>
      )}
    />
  );
};
