import React, { useMemo } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { Control, Controller } from "react-hook-form";
import { Box } from "@mui/material";
import FormLabel from "@/components/basic/FormLabel";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";

type CommonInputProps = {
  maxLength?: number;
  label?: string;
  control: Control<any, any>;
  error?: boolean;
  errorMessage?: string;
  errors: {
    [x: string]: {
      message?: string;
    };
  };
  name: string;
  onChangeAction?: () => void;
  placeholder: string;
  info?: any;
  defaultValue?: Date;
} & TextFieldProps;

export const CommonDatePicker: React.FunctionComponent<CommonInputProps> = ({
  onChangeAction,
  ...props
}) => {
  const { defaultValue, info, required, name, label, disabled } = props;

  const date = useMemo(() => {
    return defaultValue && format(new Date(defaultValue), "MM-dd-yyyy");
  }, [defaultValue]);

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange } }) => (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FormLabel
            required={required}
            name={name}
            label={label}
            info={info}
          />
          {disabled ? (
            <TextField
              disabled={disabled}
              className="common"
              variant="outlined"
              color="primary"
              hiddenLabel
              size="small"
              InputLabelProps={{
                style: { backgroundColor: "#FFFFFF" },
              }}
              inputProps={{
                sx: {
                  backgroundColor: disabled ? "none" : "#FFFFFF",
                  WebkitTextFillColor:
                    disabled && !defaultValue ? "" : "#1B2433 !important",
                },
              }}
              value={date || "--"}
              sx={{
                height: "40px",
              }}
              {...props}
              label={""}
            />
          ) : (
            <DatePicker
              disabled={disabled}
              disableFuture
              value={defaultValue}
              onChange={e => {
                onChange(e);
                onChangeAction && onChangeAction();
              }}
              label={""}
              slotProps={{
                layout: {
                  sx: {
                    backgroundColor: "#FFFFFF",
                    ".MuiPickersToolbar-root": {
                      display: "none",
                    },
                  },
                },

                inputAdornment: { position: "start" },
                textField: {
                  size: "small",
                  placeholder: "選択する",
                },
              }}
              sx={{
                height: "40px",
                backgroundColor: "#FFFFFF",
                ".MuiPickersToolbar-content": {
                  display: "none",
                },
              }}
            />
          )}
        </Box>
      )}
    />
  );
};
