import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC } from "react";
import EditIcon from "@/components/icons/EditIcon";
import RemoveIcon from "@/components/icons/RemoveIcon";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { DeviceInfoDialog } from "@/components/form/widget/DeviceTable/DeviceInfoDialog";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

type FooterStatus = "connected" | "disconnected";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    status: FooterStatus;
  }
}

const NoData = () => {
  return (
    <Typography
      sx={{
        fontSize: "0.88rem",
        fontWeight: 100,
        color: "#97A0AF",
        width: "100%",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      まだデータがありません。
    </Typography>
  );
};

export const StylingHeaderGrid: FC<{
  onChangeAction: (data: any) => void;
  defaultValue: any[];
  isDisable?: boolean;
}> = ({ onChangeAction, defaultValue, isDisable = false }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [rows, setRows] = React.useState<any>(defaultValue || []);
  const [selectedRow, setSelectedRow] = React.useState<any>({});
  const [deleteRow, setDeleteRow] = React.useState<any>({});

  const handleEdit = (value: any) => {
    setSelectedRow(value);
    setOpen(true);
  };

  const handleRemoveItem = () => {
    const index = rows.findIndex(
      (item: { id: number }) => item.id === deleteRow.id,
    );

    const newRows = [...rows];

    if (index > -1) {
      newRows.splice(index, 1);

      const updatedRows = newRows.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });

      setRows(updatedRows);
      onChangeAction(updatedRows);
    }
    setOpenDelete(false);
  };
  const handleRemove = (value: any) => {
    setDeleteRow(value);
    setOpenDelete(true);
  };

  const columns: GridColDef[] = [
    {
      field: "maker",
      headerName: "メーカー",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <TextField
          variant="standard"
          value={params.value || "--"}
          InputProps={{ disableUnderline: true, readOnly: true }}
          multiline
        />
      ),
      // width: 140,
    },

    {
      field: "deviceType",
      headerName: "型式",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <TextField
          variant="standard"
          value={params.value || "--"}
          InputProps={{ disableUnderline: true, readOnly: true }}
          multiline
        />
      ),
      // width: 140,
    },
    {
      field: "count",
      headerName: "台数",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <TextField
          variant="standard"
          value={params.value || "--"}
          InputProps={{ disableUnderline: true, readOnly: true }}
          multiline
        />
      ),
      // width: 140,
    },
    {
      field: "pid",
      headerName: "固有番号（シリアル番号等）",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      // flex: 1,
      sortable: false,
      width: 140,
      // renderHeader: params => <Box>固有番号（シリアル番号等）</Box>,
      renderCell: (params: GridRenderCellParams<any>) => (
        <TextField
          variant="standard"
          value={params.value || "--"}
          InputProps={{ disableUnderline: true, readOnly: true }}
          multiline
        />
      ),
    },
    {
      field: "power",
      headerName: "夜間待機電力（kWh）",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      width: 140,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <TextField
          variant="standard"
          value={params.value || "--"}
          InputProps={{ disableUnderline: true, readOnly: true }}
          multiline
        />
      ),
    },
    {
      field: "action",
      headerClassName: "super-app-theme--header--action",
      headerAlign: "left",
      sortable: false,
      type: "actions",
      flex: 2,
      getActions: (params: GridRowParams) => {
        if (isDisable) return [];
        return [
          <Box key={params.id} sx={{ ml: 4 }}>
            <IconButton onClick={() => handleEdit(params.row)}>
              <EditIcon />
            </IconButton>
          </Box>,
          <Box key={params.id}>
            <IconButton onClick={() => handleRemove(params.row)}>
              <RemoveIcon />
            </IconButton>
          </Box>,
        ];
      },
    },
  ];

  const handleUpdateDevice = (value: {
    maker: string;
    id: number | undefined;
  }) => {
    if (value.id) {
      const newRows = [...rows];
      const index = newRows.findIndex(item => item.id === value.id);
      if (index > -1) {
        newRows[index] = value;
        setRows(newRows);
        onChangeAction(newRows);
      }
    } else {
      const newRows = [...rows, { ...value, id: rows.length + 1 }];
      setRows(newRows);
      onChangeAction(newRows);
    }
  };
  const handleAddDevice = () => {
    setSelectedRow({});
    setOpen(true);
  };
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",

        "& .super-app-theme--header": {
          minWidth: "140px",
          backgroundColor: "#F7F8FA",
          color: "#2C31A6",
          "& > .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
            display: "none",
          },
        },
        "& .super-app-theme--header--action": {
          backgroundColor: "#F7F8FA",
          color: "#2C31A6",
          "& > .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
            display: "none",
          },
        },
      }}
    >
      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={openDelete}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            削除しますか？
          </Typography>
          <Typography
            sx={{
              my: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            この操作は元に戻すことができません。
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{ width: "150px", py: 1.2, color: "#42526E" }}
              onClick={() => setOpenDelete(false)}
            >
              キャンセル
            </Button>
            <Button
              sx={{ width: "150px", py: 1.2 }}
              color={"info"}
              onClick={handleRemoveItem}
              variant="contained"
            >
              削除
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <>
        {open && (
          <DeviceInfoDialog
            open={open}
            onSubmit={handleUpdateDevice}
            onClose={() => setOpen(false)}
            selectedValue={selectedRow}
          />
        )}
      </>

      <DataGrid
        slots={{
          noRowsOverlay: NoData,
        }}
        sx={{
          overflowX: "scroll",
          border: "none",
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1.5,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F7F8FA",
          },
        }}
        autoHeight={true}
        hideFooter={true}
        getRowHeight={() => "auto"}
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableDensitySelector
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
      />
      {isDisable ? null : (
        <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
          <Grid container>
            <Grid item sm={12} md={2.5}>
              <Button
                onClick={handleAddDevice}
                variant="contained"
                sx={{
                  py: 1,
                  px: 5,
                  mb: 1,
                  display: "flex",
                  width: `${fullScreen ? "100%" : "160px"}`,
                }}
                disabled={rows.length >= 10}
              >
                + 追加する
              </Button>
            </Grid>
            <Grid item sm={12} md={9.5}>
              <Box ml={4}>
                <Typography
                  sx={{
                    fontSize: "0.88rem",
                    fontWeight: 100,
                    color: rows.length >= 10 ? "red" : "#97A0AF",
                    width: { md: "500px", xs: "auto" },
                  }}
                >
                  “+ 追加する”をクリックして情報を追加してください。
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.88rem",
                    fontWeight: 100,
                    color: rows.length >= 10 ? "red" : "#97A0AF",
                    width: { md: "500px", xs: "auto" },
                  }}
                >
                  最大 10 個まで登録することができます。
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
export const DeviceTable: FC<{
  onChangeAction: (data: any) => void;
  defaultValue: any[];
  isDisable?: boolean;
}> = ({ onChangeAction, defaultValue, isDisable = false }) => {
  return (
    <>
      <StylingHeaderGrid
        isDisable={isDisable}
        defaultValue={defaultValue}
        onChangeAction={onChangeAction}
      />
    </>
  );
};
