import UserInfoForm from "@/components/form/UserInfoForm";
import React, { FC } from "react";
import SimpleContractForm from "@/components/form/SimpleContractForm";
import { Box } from "@mui/material";
interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: {
    userForm: any;
    contractForm: any;
  };
}
const FinalCheckFormA: FC<Props> = ({
  data,
  onError,
  pageButton: PageButton,
  onSubmit,
}) => {
  return (
    <>
      <UserInfoForm
        isDisable={true}
        data={data?.userForm}
        onError={onError}
        onSubmit={() => {}}
      />
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#F0F0F0",
          borderTop: "1px dashed #BBBCBC",
        }}
      />

      <SimpleContractForm
        isDisable={true}
        data={data?.contractForm}
        onError={onError}
        pageButton={PageButton}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default FinalCheckFormA;
