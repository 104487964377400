import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import UserInfoForm from "@/components/form/UserInfoForm";
import { PageButton } from "@/components/share/PageButton";
import { FormProviderContext } from "@/components/context/FormProvider";
import { FieldErrorDialog } from "@/components/form/FieldErrorDialog";
import SimpleContractForm from "@/components/form/SimpleContractForm";
import { SendDialog } from "@/components/form/SendDialog";
import FinishPage from "@/components/form/FinishPage";
import TimeCount from "@/components/basic/TimeCount";
import FinalCheckFormA from "@/components/form/FinalCheckFormA";

const Title = ({ page }: { page: number }) => {
  let pageTitle = "";
  let subTitle = "";
  switch (page) {
    case 1:
      pageTitle = "プラットフォームご利用予定のお客様情報を入力してください。";
      break;
    case 2:
      pageTitle = "利用規約に関する内容をご確認ください。";
      break;
    case 3:
      pageTitle = "お申し込みは、まだ完了しておりません。";
      subTitle =
        "以下の入力内容に間違いがないかご確認の上、「送信」をクリックしてください。";

      break;
    default:
  }

  return (
    <>
      <Typography
        id="top"
        variant="h2"
        component="h1"
        my={6}
        sx={{
          fontWeight: 700,
          fontSize: "2.5rem",
          mb: subTitle ? 0 : 4,
        }}
      >
        {pageTitle}
      </Typography>
      {subTitle && (
        <Typography
          id="top"
          variant="h2"
          component="h1"
          my={6}
          sx={{
            fontWeight: 700,
            fontSize: "2.5rem",
            mt: 0,
          }}
        >
          {subTitle}
        </Typography>
      )}
    </>
  );
};
const FormA = () => {
  const [userForm, setUserForm] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [contractForm, setContractForm] = useState("");
  const [sendForm, setSendForm] = useState(false);
  const [sendFormData, setSendFormData] = useState({});

  const { page, setPage } = useContext(FormProviderContext);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const addPage = (step: string) => {
    if (step === "next") {
      setPage && page && setPage(page + 1);
    }
  };
  const handleUserForm = (value: any) => {
    setUserForm(value);
    addPage(value.step);
  };
  const handleFinalCheck = (value: any) => {
    if (value.step === "next") {
      setSendForm(true);
    }
  };
  const handleContractForm = (value: any) => {
    setContractForm(value);
    addPage(value.step);

    if (value.step === "next") {
      const sendData = {
        userForm,
        contractForm: {
          question: value.question,
          checkProtect: value.checkProtect,
          checkPhone: value.checkPhone,
        },
      };
      setSendFormData(sendData);
    }
  };

  const handleError = (value: any) => {
    setErrors(value);
    setOpen(true);
  };

  return (
    <>
      <TimeCount />
      {page !== 10 && <Title page={page || 1} />}

      <Box
        sx={{
          background: page === 10 ? "inherit" : "white",
          p: 3,
          boxShadow: page === 10 ? "none" : "0px 2px 4px 0px #00000045",
        }}
      >
        <Box
          my={page === 10 ? 20 : 0}
          p={2}
          sx={{
            background: page === 10 ? "inherit" : "#F0F0F0",
            boxShadow: page === 10 ? "none" : "0px 2px 4px 0px #00000045",
          }}
        >
          {page === 1 && (
            <UserInfoForm
              data={userForm}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleUserForm}
            ></UserInfoForm>
          )}
          {page === 2 && (
            <SimpleContractForm
              data={contractForm}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleContractForm}
            />
          )}
          {page === 3 && (
            <FinalCheckFormA
              data={sendFormData}
              onError={handleError}
              pageButton={PageButton}
              onSubmit={handleFinalCheck}
            />
          )}
          {page === 10 && <FinishPage />}

          <FieldErrorDialog
            errors={errors}
            open={open}
            handleClose={() => setOpen(false)}
          />
          <SendDialog
            sendFormData={sendFormData}
            open={sendForm}
            handleClose={() => setSendForm(false)}
          />
        </Box>
      </Box>
    </>
  );
};

export default FormA;
